import React from "react";
import "./Info.css";
import { Modal } from "./Modal";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SHORT_TITLE } from "../const";

export const Biography = ({ item }) => {
  const navigate = useNavigate();

  const navigateToRoot = () => {
    navigate("/");
  };

  return (
    <Modal close={navigateToRoot}>
      <Helmet>
        <title>{SHORT_TITLE} | Biography</title>
      </Helmet>

      <div className="info__content">
        <div className="info__en">
          <h4>EN</h4>

          <h2>Marc Rudin, 1945 – 2023</h2>
          <h3>Gifted graphic artist, musician, friend and revolutionary</h3>
          <br />
          <p className="info__year-paragraph">
            <span className="info__year">Early period </span>Childhood,
            apprenticeship as a graphic artist, and first political engagements
            in Bern.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1967 </span>Marriage and birth of
            daughter.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1969 </span>Family moves to Paris; he
            works as a graphic designer and is politically active in the Renault
            Works.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1972 </span>Separation from the family
            and return to Bern.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1970s </span>He spends the 70s in Bern,
            Milan and briefly in Beirut.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1979 – 1991 </span>Exile in Beirut and
            Damascus as a poster designer for the Palestinian resistance.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1991 </span>Arrested in Turkey.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1993 </span> Sentenced to eight years
            imprisonment in Denmark for robbery (in support of the Palestinian
            resistance).
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1997 – 2011 </span>Vocational teacher
            of graphic arts in Zurich. He is active in the 1st May Committee and
            the PdA; plays folk music and composes his own pieces; designs
            scripts and posters.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">2012 </span>Diagnosis of
            Parkinson&#39;s disease.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">2023 </span>Marc dies in the Limmat
            Health Center in Zurich.
          </p>
          <hr />

          {/* <h2>Further readings</h2> */}

          <p>
            About the music of Marc Rudin:{" "}
            <a href="/#/collection/categories/article/media/1158">
              The Musician Marc Rudin
            </a>
          </p>
          <p>
            Correspondence from prison:{" "}
            <a href="/#/collection/categories/article/media/1160">
              About the cooperation with Palestinian liberation organizations
            </a>
          </p>

          <hr />

          <h2>Biography</h2>
          <p>
            Marc Rudin was born on 29 September 1945, and spent his childhood
            with his parents and two sisters in Berne. Since his mother is from
            Western Switzerland, he grew up bilingual.
          </p>
          <p>
            Marc was passionate about sports from a young age and engaged in
            athletics, ski racing and climbing in the Ostermundigen quarries.
          </p>
          <p>
            His talent for drawing and music became apparent at an early age. As
            a young boy, he often ventured out alone and sketched the landscape
            of the surrounding area. It is therefore only natural that he later
            chose to become a professional graphic designer. In 1962 he attended
            the preliminary course at the School of Arts and Crafts and in 1963
            he began an apprenticeship as a graphic artist with J. Mauerhofer in
            Ostermundigen, Berne. At the Berne School of Applied Arts he was
            strongly influenced by Hans Schwarzenbach. After successfully
            completing his apprenticeship, he worked briefly as a graphic
            designer at Scintilla in 1967/68, then at the Kurt Wirth studio, at
            Hablützel and Jaquet, and subsequently became a designer for the
            fashion magazine Harper&#39;s Bazar and designed posters for the
            cable car enterprise Firstbahn as well as the SBB. In 1967 he
            married and his daughter was born.
          </p>
          <p>
            During the 60&#39;s Marc became a member of the forum “Politicum
            Bern” and later of the RSB (“Revolutionary Socialist Movement”). On
            22 June 1968, he participated in hoisting the flag of the “National
            Front for the Liberation of South Vietnam” on the top of the Berne
            Cathedral Tower.
          </p>
          <p>
            In the fall of 1969, the family moved to Paris and he worked at the
            Reiwald advertising agency. Politically and artistically, Marc
            became involved with the French Maoist political party “Gauche
            Prolétarienne” and in the activist workers&#39; committee “Comité de
            Lutte” at Renault. He maintained contacts with the North African
            immigrants who worked in car production and identified with the
            anti-colonialist struggles and with the liberation struggle of the
            Palestinians.
          </p>
          <p>
            In 1972 Marc separated from his wife and returned to Berne, where he
            became involved in the “Schinagu d&#39;Sach fürs Vouk”. In May 1973
            he participated in a squatter house-occupation action in Berne on
            Forstweg.
          </p>
          <p>
            In 1974, he became involved in the strike at the Burger &amp; Jacobi
            piano factory in Biel and in the workers&#39; committee “Ça ira”,
            “Sich nid lo tschaupe” (Don&#39;t get kicked).
          </p>
          <p>
            After the disbandment of the “Schinagu”, Marc devoted himself to
            folk music, playing in various groups and building musical
            instruments himself based on old models. He could often be found in
            the “Café des Pyrénées” playing chess.
          </p>
          <p>
            In Milan (1975/76) he participated in the realization of graffiti on
            squatted houses in the “Porta Ticinese” neighborhood and established
            his first direct contacts with Palestinians. In 1976 he travelled to
            Lebanon for a short stay and realized his first posters for the
            Palestinian resistance.
          </p>
          <p>
            Back in Switzerland, Marc played old and new forms of folk music in
            various group formations, including with “Husmusig Jeremias”, and
            went on tour in Germany with the “Schränzern” as a street musician.
          </p>
          <p>
            In May 1980, Marc was sentenced to four years in prison in absentia
            by the “District Court of Saane Fribourg” for his involvement in the
            bombing attack of November 1979 against the IMEF bank in Fribourg.
          </p>
          <p>
            In exile in Beirut and Damascus from 1979 to 1991, Marc worked as a
            poster designer for the PFLP (“Popular Front for the Liberation of
            Palestine”) under the name “Jihad Mansour”. During the 1982 invasion
            of Lebanon by the Israeli army, he participated in the defensive
            battles of the Palestinian resistance in Beirut. After the
            unsuccessful defense of Beirut, the fighters were evacuated to
            Damascus under international supervision.
          </p>
          <p>
            In 1991, as the situation for illegals in Damascus became
            increasingly uncertain, Marc attempted to seek refuge in Turkey, was
            arrested at the border and transferred to Bayrampaşa Prison in
            Istanbul. Interpol identified Marc as one of the possible
            participants in the November 1988 robbery by the “Blekingegade Gang”
            in Copenhagen in which a young policeman lost his life. In 1992 Marc
            was extradited to Denmark, where he was sentenced to eight years in
            prison in October 1993 for robbery in support of the Palestinian
            resistance.
          </p>
          <p>
            In the security wing of the prison in Horsens, Marc spent his
            imprisonment realizing drawings, linocuts and woodcuts and kept up a
            very extensive correspondence. To keep himself physically fit, he
            pursued an intensive sports program.
          </p>
          <p>
            In June 1996, he married his longtime love Laura, who was thus
            finally able to visit him in prison.
          </p>
          <p>
            In early 1997, Marc was transferred to Fribourg, Switzerland, where
            he was to be tried again for the bombing attack on the IMEF bank.
            Due to procedural errors, he was released from prison already in
            February.
          </p>
          <p>
            In the summer of 1997, Marc realized a mural together with Colby
            Blumer in Quartierhof 5 in the Lorraine area in Berne. From then on,
            Marc lived in Zurich and worked as a vocational school teacher at
            the “Berufsschule für Gestaltung Zürich Medien Form Farbe” from 1997
            until his retirement in 2011. He taught type history, typography and
            color theory with dedication and competence. Marc also dedicated
            himself privately to typography and developed several typefaces.
          </p>
          <p>
            In the folk music group “Nahdisnah”, which he founded, he played
            cello and viola da gamba. Marc was politically active for years in
            the Labor Day organizing committee, “1. Mai Komitee Zürich” and in
            the PdA (Party of Labor) and designed various posters. He frequently
            travelled to Paris in order to spend time with his daughter and
            grandchildren. Diagnosed with Parkinson&#39;s in 2012, he fought it
            by jogging, swimming and climbing, and continued to make music and
            cultivating typefaces.
          </p>
          <p>
            The disease increasingly limited his range of activity, and from
            2020 onwards he was capable of doing very little. Parkinson&#39;s
            sapped his strength and also caused him severe back pain. From
            August 2021 he lived in the Limmat Health Center, where he was
            released from his suffering on April 7, 2023.
          </p>
          <h3>Further texts</h3>
          <ul>
            <li>
              <p>
                <strong>“Marc Rudin / Jihad Mansour” </strong>
                exhibition catalog, Zurich Bern 1993, © KASAMA Zurich,
                ISBN 3-9520506-I-X
              </p>
            </li>
            <li>
              <p>
                Marc Griesshammer “A life between resistance and folk music:
                Marc Rudin” in{" "}
                <strong>
                  “Bern 68 Local history of a global awakening - events and
                  memories”.
                </strong>{" "}
                2008, hier + jetzt, Verlag für Kultur und Geschichte Bern, ISBN
                978-3-03919-078-2
              </p>
            </li>
            <li>
              <p>Bernhard Giger “Marc Rudin / The Palestinian Revolutionary</p>
              <p>
                in{" "}
                <strong>
                  “Revolte Rausch und Razzien / Neunzehn 68er blicken zurück”,{" "}
                </strong>
                2018, Stämpfli Verlag Bern, on the occasion of the exhibition at
                the Historisches Museum Bern “<strong>1968 Schweiz</strong>“
                Nov. 2017 – June 2018, ISBN 978-3-7272-7926-3
              </p>
            </li>
          </ul>
        </div>

        <div className="info__de">
          <h4>DE</h4>
          <h2>Marc Rudin, 1945 – 2023</h2>
          <h3>Begnadeter Grafiker, Musiker, Freund und Revolutionär</h3>
          <br />

          <p className="info__year-paragraph">
            <span className="info__year">Jugend </span>Kindheit, Grafikerlehre
            und erste politische Engagements in Bern.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1967 </span>Heirat und Geburt der
            Tochter.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1969 </span>Umzug der Familie nach
            Paris, er arbeitet als Grafiker und ist politisch in den
            Renaultwerken aktiv.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1972 </span>Trennung von der Familie
            und Rückkehr nach Bern.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1970er </span>Die Siebsziger verbringt
            er in Bern, in Mailand und kurz in Beirut.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1979 – 1991 </span>Exil in Beirut und
            Damaskus als Plakatgestalter für den Palästinensischen Widerstand.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1991 </span>Verhaftung in der Türkei.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1993 </span> Verurteilung zu acht
            Jahren Haft in Dänemark wegen Raubes (zugunsten des
            palästinensischen Widerstands).
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">1997 – 2011 </span>Berufschullehrer für
            Grafik in Zürich. Er ist im 1.-Maikomitee und der PdA aktiv; spielt
            Volksmusik und komponiert eigene Stücke; gestaltet Schriften und
            Plakate.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">2012 </span>Diagnose Parkinson.
          </p>
          <p className="info__year-paragraph">
            <span className="info__year">2023 </span>stirbt er im
            Gesundheitszentrum Limmat in Zürich.{" "}
          </p>

          <hr />

          {/* <h2>Weitere Texte</h2> */}

          <p>
            Über die Musik von Marc Rudin:{" "}
            <a href="/#/collection/categories/article/media/1158">
              Der Musiker Marc Rudin
            </a>
          </p>
          <p>
            Briefwechsel aus dem Gefängnis:{" "}
            <a href="/#/collection/categories/article/media/1160">
              Über die Zusammenarbeit mit palästinensischen
              Befreiungsorganisationen
            </a>
          </p>

          <hr />

          <h2>Biografie</h2>

          <p>
            Marc Rudin kommt am 29. September 1945 auf die Welt, seine Kindheit
            verbringt er mit seinen Eltern und zwei Schwestern in Bern. Da seine
            Mutter Westschweizerin ist, wächst er bilingue auf.
          </p>
          <p>
            Marc betreibt von jung an leidenschaftlich Sport: macht
            Leichtathletik, fährt Skirennen und klettert in den
            Ostermundigensteinbrüchen.
          </p>
          <p>
            Schon früh zeigt sich sein zeichnerisches und musikalisches Talent.
            Als kleiner Bub geht er oft allein hinaus und zeichnet die
            Landschaft der näheren Umgebung ab. Es ist deshalb naheliegend, dass
            er später den Grafikerberuf wählt. 1962 macht er den Vorkurs an der
            Kunstgewerbeschule und 1963 beginnt er die Grafikerlehre bei J.
            Mauerhofer in Ostermundigen, Bern. In der Gewerbeschule Bern ist er
            stark von Hans Schwarzenbach beeinflusst. Nach erfolgreich
            abgeschlossener Lehre arbeitet er 1967/68 kurz als Grafiker bei
            Scintilla, danach im Atelier Kurt Wirth, bei Hablützel und Jaquet,
            wird dann Gestalter der Modezeitschrift Harper&#39;s Bazar und
            gestaltet Plakate für die Firstbahnen und die SBB. 1967 heiratet er
            und seine Tochter kommt auf die Welt.
          </p>
          <p>
            Während der 68er-Jahre ist Marc Mitglied des Forum «Politicum Bern»
            und später der RSB («Revolutionäre Sozialistische Bewegung»). Am 22.
            Juni 1968 beteiligt er sich beim Hissen der Flagge der «Nationalen
            Front für die Befreiung Südvietnams» auf der Spitze des Berner
            Münsterturms.
          </p>
          <p>
            Im Herbst 1969 zieht die Familie nach Paris und er arbeitet in der
            Werbeagentur Reiwald. Politisch und zeichnerisch engagiert sich Marc
            bei der «Gauche Prolétarienne» und im «Comité de Lutte» bei Renault.
            Er pflegt Kontakte zu den nordafrikanischen Immigranten, die in der
            Autoproduktion arbeiten und sich mit den antikolonialistischen
            Kämpfen und mit dem Befreiungskampf der Palästinenser*innen
            identifizieren.
          </p>
          <p>
            1972 trennt sich Marc von seiner Frau und kehrt nach Bern zurück, wo
            er sich im «Schinagu d&#39;Sach fürs Vouk» engagiert. Im Mai 1973
            ist er bei einer der ersten Häuserbesetzungen in Bern am Forstweg
            dabei.
          </p>
          <p>
            1974 engagiert er sich beim Streik in der Bieler Pianofabrik
            Burger&amp;Jacobi und im Arbeiterkomitee «Ça ira», «Sich nid lo
            tschaupe» (Sich nicht treten lassen).
          </p>
          <p>
            Nach der Auflösung des «Schinagu», widmet sich Marc der Volksmusik,
            spielt in verschiedenen Gruppen und baut selber Musikinstrumente
            nach alten Vorbildern. Er ist oft im «Café des Pyrénées» Schach
            spielend anzutreffen.
          </p>
          <p>
            In Mailand (1975/76) beteiligt er sich im Quartier «Porta Ticinese»
            an der Realisierung von Graffitis an besetzten Häusern und knüpft
            erste direkte Kontakte zu Palästinser*innen. 1976 reist er für einen
            Kurzaufenthalt in den Libanon und realisiert erste Plakate für den
            palästinensischen Widerstand.
          </p>
          <p>
            Zurück in der Schweiz spielt Marc in verschiedenen Formationen alte
            und neue Volksmusik, unter anderem mit der Formation «Husmusig
            Jeremias» und geht mit den «Schränzern» als Strassenmusiker auf
            Deutschland-Tournee.
          </p>
          <p>
            Wegen Beteiligung am Sprengstoffanschlag vom November 1979 gegen die
            IMEF-Bank in Fribourg wird Marc im Mai 1980 in Abwesenheit vom
            «Bezirksgericht Saane Freiburg» zu vier Jahren Zuchthaus verurteilt.
          </p>
          <p>
            Im Exil in Beirut und Damaskus von 1979 bis 1991 arbeitet Marc unter
            dem Namen «Jihad Mansour» als Plakatgestalter für die PFLP («Popular
            Front for the Liberation of Palestine»). Während der Invasion 1982
            der israelischen Armee im Libanon nimmt er an den
            Verteidigungskämpfen des palästinensischen Widerstandes in Beirut
            teil. Nach der vergeblichen Verteidigung Beiruts werden die Kämpfer
            unter internationaler Aufsicht nach Damaskus evakuiert.
          </p>
          <p>
            1991 als die Situation der Illegalen in Damaskus unsicherer wird,
            versucht sich Marc in die Türkei abzusetzen, wird an der Grenze
            verhaftet und ins Bayrampaşa-Gefängnis in Istanbul überführt.
            Interpol identifizierte Marc als einen der möglichen Beteiligten an
            dem Raubüberfall vom November 1988 der «Blekingegade-Bande» in
            Kopenhagen bei dem ein junger Polizist sein Leben verliert. 1992
            wird Marc nach Dänemark ausgeliefert, wo er im Oktober 1993 wegen
            Raub zugunsten des palästinensischen Widerstands zu einer
            achtjährigen Haftstrafe verurteilt wird.
          </p>
          <p>
            Im Sicherheitstrakt des Gefängnisses in Horsens verbringt Marc seine
            Haft mit der Realisierung von Zeichnungen, Linol- und Holzschnitten
            und führt eine sehr umfangreiche Briefkorrespondenz. Um sich
            körperlich fit zu halten, betreibt er ein intensives Sportprogramm.
          </p>
          <p>
            Im Juni 1996 heiratet er seine langjährige Liebe Laura, die ihn
            somit endlich im Gefängnis besuchen kann.
          </p>
          <p>
            Anfangs 1997 wird Marc in die Schweiz nach Fribourg überführt, wo er
            für den Sprengstoffanschlag auf die IMEF-Bank noch einmal vor
            Gericht gestellt werden soll. Wegen Verfahrensfehlern wird er
            bereits im Februar aus der Haft entlassen.
          </p>
          <p>
            In Freiheit realisiert Marc im Sommer 1997 mit Colby Blumer zusammen
            ein Wandbild im Quartierhof 5 in der Lorraine in Bern. Marc lebt
            fortan in Zürich und arbeitet ab 1997 bis zu seiner Pensionierung
            2011 als Berufschullehrer an der «Berufsschule für Gestaltung Zürich
            Medien Form Farbe». Er unterrichtet engagiert und kompetent
            Schriftgeschichte, Typographie und Farbenlehre. Marc widmete sich
            auch privat der Typographie und entwickelte mehrere Schriften.
          </p>
          <p>
            In der Volksmusikgruppe «Nahdisnah», die er gegründet hat, spielte
            er Cello und Gambe. Politisch aktiv ist Marc während Jahren im «1.
            Mai Komitee Zürich» und in der PdA (Partei der Arbeit) und gestaltet
            diverse Plakate. Marc reist viel nach Paris, um Zeit mit seiner
            Tochter und den Enkelkindern zu verbringen. 2012 wird bei ihm
            Parkinson diagnostiziert, er kämpft dagegen an, geht Joggen,
            Schwimmen und Klettern, macht weiterhin Musik und entwickelt
            Schriften.
          </p>
          <p>
            Die Krankheit schränkt ihn aber immer mehr ein, ab 2020 ist für ihn
            nicht mehr viel möglich. Der Parkinson zehrt an seinen Kräften und
            bereitet ihm auch heftige Rückenschmerzen. Ab August 2021 lebt er im
            Gesundheitszentrum Limmat wo er am 7. April 2023 von seinem Leiden
            erlöst wird.
          </p>

          <h3>Weiterführende Texte</h3>

          <ul>
            <li>
              <strong>«Marc Rudin / Jihad Mansour»</strong> Katalog zur
              Ausstellung, Zürich Bern 1993, © KASAMA Zürich, ISBN 3-9520506-I-X
            </li>
            <li>
              <p>
                Marc Griesshammer «Ein Leben zwischen Widerstand und Volksmusik:
                Marc Rudin» in{" "}
                <strong>
                  «Bern 68 Lokalgeschiche eines globalen Aufbruchs – Ereignisse
                  und Erinnerungen»
                </strong>{" "}
                2008, hier + jetzt, Verlag für Kultur und Geschichte Bern, ISBN
                978-3-03919-078-2
              </p>
            </li>
            <li>
              <p>
                Bernhard Giger «Marc Rudin / Der Palästinensische Revolutionär»
                in{" "}
                <strong>
                  «Revolte Rausch und Razzien / Neunzehn 68er blicken zurück»
                </strong>
                , 2018 Stämpfli Verlag Bern, anlässlich der Ausstellung im
                Historischen Museum Bern <strong>«1968 Schweiz»</strong> Nov.
                2017 – Juni 2018, ISBN 978-3-7272-7926-3
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};
