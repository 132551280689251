import React, { useContext } from "react";
import { DataContext } from "./DataContext";
import "./Search.css";

export const Search = () => {
  const [state, dispatch] = useContext(DataContext);
  const { search } = state;

  const onInputChange = (e) => {
    const value = e.target.value;
    dispatch({ type: "SET_SEARCH", payload: value });
  };

  return (
    <div className="search">
      <input
        type="search"
        value={search}
        onChange={onInputChange}
        placeholder="Search..."
      />
    </div>
  );
};
