import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./ImageList.css";
import { Image } from "./Image";
import { Link } from "react-router-dom";

const CHUNK_SIZE = 18;

export const ImageList = ({ images, showDetail }) => {
  const [items, setItems] = useState(images.slice(0, CHUNK_SIZE));
  const [hasMore, setHasMore] = useState(images.length > CHUNK_SIZE);

  useEffect(() => {
    // Reset the items and hasMore states when images prop changes
    setItems(images.slice(0, CHUNK_SIZE));
    setHasMore(images.length > CHUNK_SIZE);
  }, [images]);

  const fetchMoreData = () => {
    // Calculate the index to start slicing from
    const startIndex = items.length;

    // Calculate the index to end slicing at
    const endIndex = startIndex + CHUNK_SIZE;

    // Get the next chunk from the images array
    const newItems = images.slice(startIndex, endIndex);

    // Concatenate the new chunk with the existing items
    setItems([...items, ...newItems]);

    if (endIndex >= images.length) {
      setHasMore(false);
    }
  };

  return (
    <div className="image-list">
      <h3  className="image-list__count">Fund: {images.length} items <span  className="image-list__comment">(alphabetical order)</span></h3>

      <InfiniteScroll
        className="image-list__list"
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        {items.map((item, index) => (
          <Link
            to={`./media/${item.id}`}
            className="image-list__item"
            key={item.id}
          >
            <Image
              hash={item.hash}
              width={800}
              ratio={item.ratio}
              mainColor={item.mainColor}
            />
            <h2 className="image-list__title">
              {item.title || "no title"}{" "}
              <span className="image-list__year">{item.year}</span>
            </h2>
          </Link>
        ))}
      </InfiniteScroll>
    </div>
  );
};
