import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import SearchPage from "./components/SearchPage";
import { HashRouter } from "react-router-dom";
import { DataContext } from "./components/DataContext";
import { Spinner } from "./components/Spinner";
import { slugify } from "./components/slugify";
import { astToText } from "./packages/signalwerk.md/src/ast";

import "./App.css";

function buildSearchString(item) {
  const words = [];
  if (item.title) {
    words.push(...item.title.split(" "));
  }
  if (item.description) {
    words.push(...astToText(item.description).split(" "));
  }
  if (item.year) {
    words.push(item.year);
  }
  if (item.category) {
    words.push(...item.category.split(" "));
  }
  if (item.medium) {
    words.push(...item.medium.split(" "));
  }
  if (item.technic) {
    words.push(...item.technic.split(" "));
  }
  if (item.client) {
    words.push(...item.client.split(" "));
  }
  if (item.tags && item.tags.lenth) {
    words.push(...item.tags);
  }

  return [...new Set(words.map((item) => item.toLowerCase()))].join(" ");
}

function App() {
  const [state, dispatch] = useContext(DataContext);

  const { isLoading } = state;

  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    fetch("https://marc-media.signalwerk.ch/data.json")
      .then((response) => response.json())
      .then((jsonData) => {
        const data = jsonData.data.media.map((item) => {
          const search = buildSearchString(item);
          return {
            ...item,
            search,
          };
        });

        const categories = jsonData.data.categories.map((item) => ({
          name: item,
          slug: slugify(item),
        }));

        dispatch({ type: "SET_DATA", payload: data });
        dispatch({
          type: "SET_CATEGORIES",
          payload: categories,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      });
  }, [dispatch]);

  return (
    <div className="App">
      <Helmet>
        <meta
          name="description"
          content="Marc Rudin (Jihad Mansour), a Graphic Designer from Switzerland, created posters for the Palestinian resistance."
        />
        <meta
          name="keywords"
          content="Marc Rudin, Palestinian resistance, Switzerland, graphic design, poster, illustration, typeface"
        />
      </Helmet>

      <HashRouter>{isLoading ? <Spinner /> : <SearchPage />}</HashRouter>
    </div>
  );
}

export default App;
