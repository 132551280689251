import React from "react";
import "./DetailImage.css";
import { Image } from "./Image";
import { Modal } from "./Modal";
import {
  renderNode,
  extractPlainText,
} from "../packages/signalwerk.md/src/render.jsx";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { Helmet } from "react-helmet-async";
import { SHORT_TITLE } from "../const";

function truncateString(str, maxLength = 500) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "...";
  }
  return str;
}

export const DetailImage = ({ item }) => {
  const navigate = useNavigate();
  const close = () => {
    navigate("..");
  };
  const title = item.title || "no title";

  return (
    <Modal close={close}>
      <Helmet>
        <title>
          {SHORT_TITLE} | {title}
        </title>
        {item.description && (
          <meta
            name="description"
            content={truncateString(extractPlainText(item.description))}
          />
        )}
      </Helmet>

      <div
        className={`detailImage__content detailImage__content--${
          item.ratio < 1 ? "portrait" : "wide"
        }`}
      >
        <div className="detailImage__image">
          <Image
            hash={item.hash}
            width={2400}
            ratio={item.ratio}
            mainColor={item.mainColor}
          />
        </div>

        <div className="detailImage__text">
          {!item.hiddenTitlesInText && <h2>{title}</h2>}
          {item.description && (
            <div>
              {!item.hiddenTitlesInText && <h3>Description</h3>}
              {renderNode(item.description)}
              {item.zhdk && (
                <p>
                  In the poster collection of the Zurich University of the Arts
                  (ZHDK). <br />
                  Archive number:{" "}
                  <a
                    className=""
                    href={`https://www.emuseum.ch/search/${item.zhdk}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.zhdk}
                  </a>
                </p>
              )}
            </div>
          )}
          <h3 className="detailImage__heading">Information</h3>
          <ul>
            {item.year && <li>Year: {item.year}</li>}
            {item.client && <li>Client: {item.client}</li>}
            {item.medium && <li>Medium: {item.medium}</li>}
            {item.width && item.height && (
              <li>
                Dimension: {item.width} mm × {item.height} mm
              </li>
            )}
            {item.technic && <li>Technic: {item.technic}</li>}
            {item.category && <li>Category: {item.category}</li>}
            <li>Media-ID: {item.id}</li>
          </ul>

          {item.download && (
            <p>
              <a
                href={`https://marc-media.signalwerk.ch/img/fullsize/marcrudin-org--${item.id}.jpg`}
                download={`marcrudin-org--${item.id}.jpg`}
                onClick={(e) => {
                  e.preventDefault();
                  saveAs(
                    `https://marc-media.signalwerk.ch/img/fullsize/marcrudin-org--${item.id}.jpg`,
                    `marcrudin-org--${item.id}.jpg`
                  );
                }}
              >
                Download image
              </a>
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};
