import React, { useState } from "react";

export const Image = ({ width, hash, ratio, mainColor }) => {
  const [, setLoading] = useState(true);

  return (
    <div
      style={{
        aspectRatio: `${ratio} / 1`,
        position: "relative",
      }}
    >
      <img
        onLoad={() => setLoading(false)}
        src={`https://marc-media.signalwerk.ch/img/w${width}/${hash}.jpg`}
        alt=""
      />
    </div>
  );
};
