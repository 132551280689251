import React from "react";
import "./Info.css";
import { Modal } from "./Modal";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SHORT_TITLE } from "../const";

export const Info = ({ item }) => {
  const navigate = useNavigate();

  const navigateToRoot = () => {
    navigate("/");
  };

  return (
    <Modal close={navigateToRoot}>
      <Helmet>
        <title>{SHORT_TITLE} | Info</title>
      </Helmet>

      <div className="info__content">
        <div className="info__en">
          <h4>EN</h4>
          <h2>
            Welcome to the comprehensive archive of Marc Rudin – Jihad Mansour!
          </h2>
          <br />
          <p>
            Here you will encounter the versatile work of an extraordinary
            graphic artist and politically engaged personality.
          </p>
          <p>
            On display are political posters, drawings, watercolors,
            illustrations, signets and typeface designs.
          </p>
          <p>
            The political posters were created in exile in Beirut and Damascus
            from 1979 to 1991 for the Palestinian resistance.
          </p>
          <p>
            Back in Switzerland, he devoted himself passionately to typeface
            design.
          </p>

          <h3 className="info__heading">Right of use</h3>
          <p>
            The copyright to all works by Marc Rudin is held by Eleonora
            Zimmermann Rudin.
          </p>
          <p>
            The works under the heading “Download” are licensed under:{" "}
            <a href="https://creativecommons.org/licenses/by-sa/4.0/">
              CC-BY-SA 4.0
            </a>
          </p>
          <p>
            Marc used his works to fight discrimination and anti-racism. His
            works stand for international solidarity. The works may be
            downloaded and reproduced for use in this sense in the “Download”
            section, but not modified.
          </p>
          <p>All other works are protected by copyright (©).</p>
          <p>
            These works cannot be downloaded freely. However, they may also be
            made available in certain circumstances for exhibitions and
            publications. Please contact Eleonora Zimmermann Rudin regarding
            information for such use.
          </p>

          <h3 className="info__heading">Contact</h3>
          <p>
            For inquiries regarding the use of Marc Rudin's works, for example
            for exhibitions or publications:{" "}
            <a href="mailto:laurazia@bluewin.ch">contact</a>.
          </p>
          <h3 className="info__heading">Impressum</h3>
          <p>
            Project management and digitization:{" "}
            <a
              href="https://studioeusebio.com/"
              target="_blank"
              rel="noreferrer"
            >
              Studio Eusebio
            </a>
            <br />
            Website:{" "}
            <a href="https://signalwerk.ch/" target="_blank" rel="noreferrer">
              Stefan Huber
            </a>
            <br />
          </p>
        </div>

        <div className="info__de">
          <h4 className="info__lang">DE</h4>
          <h2>
            Willkommen im umfassenden Archiv von Marc Rudin – Jihad Mansour!
          </h2>
          <br />

          <p>
            Hier ist das vielseitige Werk eines aussergewöhnlichen Grafikers und
            politisch engagierten Menschen zu finden.
          </p>
          <p>
            Zu sehen sind politische Plakate, Zeichnungen, Aquarelle,
            Illustrationen, Signete und Type Design.
          </p>
          <p>
            Die politischen Plakate sind im Exil in Beirut und Damaskus von 1979
            bis 1991 für den palästinensischen Widerstand entstanden.
          </p>
          <p>
            Zurück in der Schweiz widmete er sich leidenschaftlich der
            Schriftgestaltung.
          </p>

          <h3 className="info__heading">Nutzungsrecht</h3>
          <p>
            Das Urheberrecht an sämtlichen Werken von Marc Rudin liegt bei
            Eleonora Zimmermann Rudin.
          </p>
          <p>
            Die Werke unter der Rubrik «Download» sind lizensiert unter:{" "}
            <a href="https://creativecommons.org/licenses/by-sa/4.0/">
              CC-BY-SA 4.0
            </a>
          </p>
          <p>
            Marc hat sich mit seinen Werken gegen Diskriminierung und
            Antirassismus engagiert. Seine Werke stehen für internationale
            Solidarität. Die Werke dürfen für eine Verwendung in diesem Sinne in
            der Rubrik «Download» heruntergeladen und vervielfältigt, jedoch
            nicht verändert werden.
          </p>
          <p>Alle anderen Werke sind urheberrechtlich geschützt (©).</p>
          <p>
            Diese Werke können nicht frei heruntergeladen werden. Sie stehen
            aber für Ausstellungen und Publikationen unter Umständen ebenfalls
            zur Verfügung. Bitte wenden Sie sich für die Nutzung an Eleonora
            Zimmermann Rudin.
          </p>

          <h3 className="info__heading">Kontakt</h3>
          <p>
            Für Anfragen bezüglich Nutzung der Werke Marc Rudins, zum Beispiel
            für Ausstellungen oder Publikationen:{" "}
            <a href="mailto:laurazia@bluewin.ch">Kontakt</a>.
          </p>

          <h3 className="info__heading">Impressum</h3>
          <p>
            Projektleitung und Digitalisierung:{" "}
            <a
              href="https://studioeusebio.com/"
              target="_blank"
              rel="noreferrer"
            >
              Studio Eusebio
            </a>
            <br />
            Website:{" "}
            <a href="https://signalwerk.ch/" target="_blank" rel="noreferrer">
              Stefan Huber
            </a>
            <br />
          </p>
        </div>
      </div>
    </Modal>
  );
};
