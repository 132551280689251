import React, { useEffect } from "react";
import "./Modal.css";

export const Modal = ({ children, close }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        close();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    // clean up function
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [close]);

  const handleClickOutside = (event) => {
    if (event.target.className === "modal") {
      close();
    }
  };

  return (
    <div className="modal" onClick={handleClickOutside}>
      <div className="modal__content">
        <div className="modal__header">
          <button
            type="button"
            className="modal__close"
            aria-label="Close"
            onClick={() => close()}
          ></button>
        </div>
        <div className="modal__inner">{children}</div>
      </div>
    </div>
  );
};
