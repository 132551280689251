import React from "react";
import { NavLink } from "react-router-dom";
import "./CategoryFilter.css";

export const CategoryFilter = ({ categories, onCategoryClick }) => {
  const renderCategory = [{ name: "All", slug: "all" }, ...categories];

  return (
    <div className="category-filter">
    <div className="category-filter__list">
      {renderCategory.map((category) => (
        <NavLink
          to={`/collection/categories/${category.slug}`}
          className={({ isActive }) =>
            `category-filter__item category-filter__item--${isActive ? "active" : "inactive"}`
          }
          key={category.slug}
        >
          {category.name}
        </NavLink>
      ))}
    </div>
    </div>
  );
};
