import React, { useEffect, useContext, useState } from "react";
import { ImageList } from "./ImageList";
import { CategoryFilter } from "./CategoryFilter";
import { Search } from "./Search";
import { Marc } from "./Marc";
import { Info } from "./Info";
import { Biography } from "./Biography";
import { DetailImage } from "./DetailImage";
import { Route, Routes, Navigate, Outlet, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SHORT_TITLE } from "../const";

import { DataContext } from "./DataContext";
import "./Home.css";

function Home() {
  const [state, dispatch] = useContext(DataContext);
  const [filteredImages, setFilteredImages] = useState([]);

  const { data, categories, search, selectedCategory } = state;

  const { categorySlug } = useParams();

  const onCategoryClick = (category) => {
    dispatch({ type: "SET_SELECTED_CATEGORY", payload: category });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [categorySlug]);

  useEffect(() => {
    const categoryName = categorySlug
      ? categories.find((item) => item.slug === categorySlug)
      : null;

    const newImages = data.filter((item) => {
      const searchTerm = search.trim().toLowerCase();

      let match = false;

      // search term check
      if (searchTerm === "") {
        match = true;
      }

      // check in description
      if (item.search.includes(searchTerm)) {
        match = true;
      }

      if (categoryName) {
        if (match) {
          match = item.categories.includes(categoryName.name);
        }
      }

      return match;
    });
    setFilteredImages(newImages);
  }, [data, search, categories, selectedCategory, categorySlug]);

  return (
    <div className="home">
      <Helmet>
        <title>{SHORT_TITLE}</title>
      </Helmet>
      <div className="home__header">
        <Marc />
        <Search />
        <CategoryFilter
          categories={categories}
          onCategoryClick={onCategoryClick}
        />
      </div>

      <ImageList images={filteredImages} />
      <Outlet />
    </div>
  );
}

const SearchPage = () => {
  const [state] = useContext(DataContext);

  const { data } = state;

  function DetailOverlay() {
    // Get the userId param from the URL.
    const { objectId } = useParams();
    const findId = parseInt(objectId, 10);
    const detail = data.find((item) => {
      return item.id === findId;
    });

    return <DetailImage item={detail} />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/collection/categories/all" replace={true} />}
        />
        <Route path="/collection/categories/:categorySlug" element={<Home />}>
          <Route path="media/:objectId" element={<DetailOverlay />}></Route>
        </Route>
        <Route path="/info" element={<Info />}></Route>
        <Route path="/biography" element={<Biography />}></Route>
        <Route
          path="*"
          element={<Navigate to="/collection/categories/all" replace={true} />}
        ></Route>
      </Routes>
    </>
  );
};

export default SearchPage;
