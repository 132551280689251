import { createContext, useReducer } from "react";

export const DataContext = createContext([]);

const initialState = {
  data: [],
  categories: [],
  search: "",
  selectedCategory: null,
  isLoading: true,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_DATA": {
      return {
        ...state,
        data: action.payload,
      };
    }
    case "SET_CATEGORIES": {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case "SET_SEARCH": {
      return {
        ...state,
        search: action.payload.toLowerCase(),
      };
    }
    case "SET_SELECTED_CATEGORY": {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    }
    case "SET_LOADING": {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    default:
      return state;
  }
}

export const DataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DataContext.Provider value={[state, dispatch]}>
      {children}
    </DataContext.Provider>
  );
};
