import React from "react";
import "./Marc.css";
import { Link } from "react-router-dom";
import { LONG_TITLE } from "../const";

export const Marc = () => {
  return (
    <div className="marc">
      <h1>{LONG_TITLE}</h1>
      <Link className="marc__info" to={`/info`}>
        Info
      </Link>
      <Link className="marc__info" to={`/biography`}>
        Biography
      </Link>
    </div>
  );
};
